/* eslint-disable prettier/prettier */
// FORM CONSTANTS

import CertificateIcon from "../assets/CertificateIcon";
import CrownCheckIcon from "../assets/CrownCheckIcon";
import DocumentIcon from "../assets/DocumentIcon";
import GraduationHatIcon from "../assets/GraduationHatIcon";
import SuitcaseFilledIcon from "../assets/SuitcaseFilledIcon";
import WalletV2Icon from "../assets/WalletV2Icon";

export const LEARN_PAGE_TYPE = {
	COLLEGE_COURSE: "COLLEGE COURSE",
	GENERIC: "GENERIC",
};

export const STATES = [
	"Andaman & Nicobar",
	"Andhra Pradesh",
	"Arunachal Pradesh",
	"Assam",
	"Bihar",
	"Chandigarh",
	"Chhattisgarh",
	"Dadra & Nagar Haveli",
	"Daman & Diu",
	"Delhi",
	"Goa",
	"Gujarat",
	"Haryana",
	"Himachal Pradesh",
	"Jammu & Kashmir",
	"Jharkhand",
	"Karnataka",
	"Kerala",
	"Ladakh",
	"Lakshadweep",
	"Madhya Pradesh",
	"Maharashtra",
	"Manipur",
	"Meghalaya",
	"Mizoram",
	"Nagaland",
	"Odisha",
	"Puducherry",
	"Punjab",
	"Rajasthan",
	"Sikkim",
	"Tamil Nadu",
	"Telangana",
	"Tripura",
	"Uttar Pradesh",
	"Uttarakhand",
	"West Bengal",
];

export const DEGREES = [
	"Online B.Com",
	"Online BBA",
	"Online BCA",
	"Online BA",
	"Online MBA",
	"Online MCA",
	"Online M.Com",
	"Online M.Sc",
	"Online MA",
];

// export const SPECIALIZATIONS = {
// 	"B.Com": [
// 		"Not sure",
// 		"Accounting and Finance",
// 		"International Finance and Accounting (Accredited by ACCA, UK)",
// 		"Corporate Accounting (Benchmarked to CA-Ind: Foundation, Intermediate and Final Syllabus)",
// 	],
// 	BBA: [
// 		"Not sure",
// 		"Human Resource Management",
// 		"Finance",
// 		"Marketing",
// 		"Engineering and Project Management",
// 		"General",
// 		"Logistics & Supply chain",
// 		"Marketing Management",
// 		"Event Management (EVM)",
// 		"Banking",
// 	],
// 	BCA: ["Not sure", "Computer Science and IT"],
// 	BA: [
// 		"Not sure",
// 		"Journalism and Mass Communication",
// 		"Psychology",
// 		"Political Science",
// 		"Economics",
// 		"English",
// 		"History",
// 		"Sociology",
// 		"Fine Arts",
// 	],
// 	MBA: [
// 		"Not sure",
// 		"Human Resource Management",
// 		"Finance",
// 		"Marketing",
// 		"General Management",
// 		"Systems and Operations Management",
// 		"Finance and Marketing",
// 		"Human Resource Management and Finance",
// 		"Marketing and Human Resource Management",
// 		"Healthcare Management",
// 		"Information Technology Management",
// 		"Logistics and Supply Chain Management",
// 		"International Finance (Accredited by ACCA, UK)",
// 		"Business Intelligence and Analytics (Accredited by IoA, UK)",
// 		"Project Management",
// 		"Entrepreneurship and Leadership",
// 		"Retail Banking Operations",
// 		"Finance and Human Resource Management",
// 		"Information Technology",
// 		"Business Analytics",
// 		"Operations Management",
// 	],
// 	MCA: [
// 		"Not sure",
// 		"Computer Science and IT",
// 		"Cyber Security",
// 		"Data Analytics (Accredited by IoA, UK)",
// 		"Data Science",
// 	],
// 	"M.Com": ["Accounting and Finance", "International Finance (Accredited by ACCA, UK)"],
// 	"M.Sc": ["Data Science", "Business Analytics"],
// 	MA: [
// 		"Not sure",
// 		"English",
// 		"Public Policy",
// 		"Economics",
// 		"Journalism and Mass Communication",
// 	],
// };

// export const FEE_BUDGET: string[] = [
// 	"Not sure",
// 	"₹50,000 - ₹1 lakh",
// 	"₹1 lakh - ₹1.5 lakhs",
// 	"₹1.5 lakhs - ₹2 lakhs",
// 	"₹2 lakhs - ₹3 lakhs",
// ];

export const PREFERRED_COLLEGES = {
	"Online B.Com": [
		{ name: "Not sure", fee: "", degreeSlug: "bcom" },
		{ name: "Jain University", fee: "₹1,27,500", degreeSlug: "bcom" },
		{
			name: "Amity University",
			fee: "₹99,000",
			degreeSlug: "bcom",
			collegeSlug: "amity-university-online",
		},
		{
			name: "Manipal University",
			fee: "₹99,000",
			degreeSlug: "bcom",
			collegeSlug: "manipal-university-online",
		},
		{
			name: "Amrita University",
			fee: "₹1,20,000",
			degreeSlug: "bcom",
			collegeSlug: "amrita-university-online",
		},
	],
	"Online BBA": [
		{ name: "Not sure", fee: "", degreeSlug: "bba" },
		{
			name: "Jain University",
			fee: "₹1,95,000",
			degreeSlug: "bba",
			collegeSlug: "jain-university-online",
		},
		{
			name: "Amity University",
			fee: "₹1,65,000",
			degreeSlug: "bba",
			collegeSlug: "amity-university-online",
		},
		{
			name: "Chandigarh University",
			fee: "₹1,26,000",
			degreeSlug: "bba",
			collegeSlug: "chandigarh-university-online",
		},
		{
			name: "Manipal University",
			fee: "₹1,35,000",
			degreeSlug: "bba",
			collegeSlug: "manipal-university-online",
		},
		{
			name: "Sharda University",
			fee: "₹1,05,000",
			degreeSlug: "bba",
			collegeSlug: "sharda-university-online",
		},
		{
			name: "DY Patil University",
			fee: "₹1,45,400",
			degreeSlug: "bba",
			collegeSlug: "dy-patil-university-online",
		},
		{
			name: "Amrita University",
			fee: "₹1,50,000",
			degreeSlug: "bba",
			collegeSlug: "amrita-university-online",
		},
		{
			name: "Vignan University",
			fee: "₹1,20,000",
			degreeSlug: "bba",
			collegeSlug: "vignan-university-online",
		},
	],
	"Online BCA": [
		{ name: "Not sure", fee: "", degreeSlug: "bca" },
		{
			name: "Amity University",
			fee: "₹1,50,000",
			degreeSlug: "bca",
			collegeSlug: "amity-university-online",
		},
		{
			name: "Lovely Professional University",
			fee: "₹1,32,960",
			degreeSlug: "bca",
			collegeSlug: "lovely-professional-university-online",
		},
		{
			name: "Chandigarh University",
			fee: "₹1,28,000",
			degreeSlug: "bca",
			collegeSlug: "chandigarh-university-online",
		},
		{
			name: "Manipal University",
			fee: "₹1,35,000",
			degreeSlug: "bca",
			collegeSlug: "manipal-university-online",
		},
		{
			name: "Sharda University",
			fee: "₹1,05,000",
			degreeSlug: "bca",
			collegeSlug: "sharda-university-online",
		},
		{
			name: "Amrita University",
			fee: "₹1,50,000",
			degreeSlug: "bca",
			collegeSlug: "amrita-university-online",
		},
		{
			name: "Vignan University",
			fee: "₹1,20,000",
			degreeSlug: "bca",
			collegeSlug: "vignan-university-online",
		},
	],
	"Online BA": [
		{ name: "Not sure", fee: "", degreeSlug: "ba" },
		{
			name: "Amity University",
			fee: "₹99,000",
			degreeSlug: "ba",
			collegeSlug: "amity-university-online",
		},
		{
			name: "Lovely Professional University",
			fee: "₹89,760",
			degreeSlug: "ba",
			collegeSlug: "lovely-professional-university-online",
		},
		{
			name: "Chandigarh University",
			fee: "₹1,35,000",
			degreeSlug: "ba",
			collegeSlug: "chandigarh-university-online",
		},
		{
			name: "Manipal University",
			fee: "₹75,000",
			degreeSlug: "ba",
			collegeSlug: "manipal-university-online",
		},
		{
			name: "Sharda University",
			fee: "₹1,05,000",
			degreeSlug: "ba",
			collegeSlug: "sharda-university-online",
		},
	],
	"Online MBA": [
		{ name: "Not sure", fee: "", degreeSlug: "mba", collegeSlug: "" },
		{
			name: "Jain University",
			fee: "₹1,96,000",
			degreeSlug: "mba",
			collegeSlug: "jain-university-online",
		},
		{
			name: "Amity University",
			fee: "₹1,99,000",
			degreeSlug: "mba",
			collegeSlug: "amity-university-online",
		},
		{
			name: "Lovely Professional University",
			fee: "₹1,31,840",
			degreeSlug: "mba",
			collegeSlug: "lovely-professional-university-online",
		},
		{
			name: "Chandigarh University",
			fee: "₹1,58,000",
			degreeSlug: "mba",
			collegeSlug: "chandigarh-university-online",
		},
		{
			name: "Manipal University",
			fee: "₹1,75,000",
			degreeSlug: "mba",
			collegeSlug: "manipal-university-online",
		},
		{
			name: "Sharda University",
			fee: "₹1,60,000",
			degreeSlug: "mba",
			collegeSlug: "sharda-university-online",
		},
		{
			name: "DY Patil University",
			fee: "₹1,89,400",
			degreeSlug: "mba",
			collegeSlug: "dy-patil-university-online",
		},
		{
			name: "Amrita University",
			fee: "₹1,99,000",
			degreeSlug: "mba",
			collegeSlug: "amrita-university-online",
		},
		{
			name: "Vignan University",
			fee: "₹1,10,000",
			degreeSlug: "mba",
			collegeSlug: "vignan-university-online",
		},
	],
	"Online MCA": [
		{ name: "Not sure", fee: "", degreeSlug: "mca" },
		{
			name: "Jain University",
			fee: "₹1,50,000",
			degreeSlug: "mca",
			collegeSlug: "jain-university-online",
		},
		{
			name: "Amity University",
			fee: "₹1,70,000",
			degreeSlug: "mca",
			collegeSlug: "amity-university-online",
		},
		{
			name: "Lovely Professional University",
			fee: "₹1,03,040",
			degreeSlug: "mca",
			collegeSlug: "lovely-professional-university-online",
		},
		{
			name: "Chandigarh University",
			fee: "₹1,05,000",
			degreeSlug: "mca",
			collegeSlug: "chandigarh-university-online",
		},
		{
			name: "Manipal University",
			fee: "₹1,58,000",
			degreeSlug: "mca",
			collegeSlug: "manipal-university-online",
		},
		{
			name: "Sharda University",
			fee: "₹1,20,000",
			degreeSlug: "mca",
			collegeSlug: "sharda-university-online",
		},
		{
			name: "Amrita University",
			fee: "₹1,40,000",
			degreeSlug: "mca",
			collegeSlug: "amrita-university-online",
		},
		{
			name: "Vignan University",
			fee: "₹1,10,000",
			degreeSlug: "mca",
			collegeSlug: "vignan-university-online",
		},
	],
	"Online M.Com": [
		{ name: "Not sure", fee: "", degreeSlug: "mcom" },
		{
			name: "Jain University",
			fee: "₹1,30,000",
			degreeSlug: "mcom",
			collegeSlug: "jain-university-online",
		},
		{
			name: "Amity University",
			fee: "₹1,20,000",
			degreeSlug: "mcom",
			collegeSlug: "amity-university-online",
		},
		{
			name: "Lovely Professional University",
			fee: "₹74,240",
			degreeSlug: "mcom",
			collegeSlug: "lovely-professional-university-online",
		},
		{
			name: "Manipal University",
			fee: "₹1,08,000",
			degreeSlug: "mcom",
			collegeSlug: "manipal-university-online",
		},
		{
			name: "Sharda University",
			fee: "₹1,05,000",
			degreeSlug: "mcom",
			collegeSlug: "sharda-university-online",
		},
		{
			name: "Amrita University",
			fee: "₹90,000",
			degreeSlug: "mcom",
			collegeSlug: "amrita-university-online",
		},
	],
	"Online M.Sc": [
		{ name: "Not sure", fee: "", degreeSlug: "msc" },
		{
			name: "Amity University",
			fee: "₹2,50,000",
			degreeSlug: "msc",
			collegeSlug: "amity-university-online",
		},
		{
			name: "Lovely Professional University",
			fee: "₹59,840",
			degreeSlug: "msc",
			collegeSlug: "lovely-professional-university-online",
		},
		{
			name: "Chandigarh University",
			fee: "₹75,000",
			degreeSlug: "msc",
			collegeSlug: "chandigarh-university-online",
		},
		{
			name: "Manipal University",
			fee: "₹2,80,000",
			degreeSlug: "msc",
			collegeSlug: "manipal-university-online",
		},
	],
	"Online MA": [
		{ name: "Not sure", fee: "", degreeSlug: "ma" },
		{
			name: "Jain University",
			fee: "₹90,000",
			degreeSlug: "ma",
			collegeSlug: "jain-university-online",
		},
		{
			name: "Amity University",
			fee: "₹1,70,000",
			degreeSlug: "ma",
			collegeSlug: "amity-university-online",
		},
		{
			name: "Lovely Professional University",
			fee: "₹59,840",
			degreeSlug: "ma",
			collegeSlug: "lovely-professional-university-online",
		},
		{
			name: "Chandigarh University",
			fee: "₹75,000",
			degreeSlug: "ma",
			collegeSlug: "chandigarh-university-online",
		},
		{
			name: "Manipal University",
			fee: "₹80,000",
			degreeSlug: "ma",
			collegeSlug: "manipal-university-online",
		},
	],
};

export const HIGHEST_EDUCATION = {
	TENTH_PASS: "10th Pass or Below",
	IN_ELEVENTH_TWELFTH: "Currently in 11th-12th",
	TWELFTH_PASS: "12th Pass",
	DIPLOMA: "Diploma",
	ITI: "ITI",
	CURRENTLY_GRADUATING: "Currently Doing Graduation",
	GRADUATION_COMPLETED: "Graduation Completed",
	POSTGRADUATE: "Postgraduate",
};

export const MASTERS_DEGREES = [
	"Online MBA",
	"Online MCA",
	"Online M.Com",
	"Online M.Sc",
	"Online MA",
	"Other",
];

export const BACHELORS_DEGREES = [
	"Online B.Com",
	"Online BBA",
	"Online BCA",
	"Online BA",
	"Other",
];

export const FAQS = {
	header: "Frequently Asked Questions",
	data: [
		{
			title: "Are Online Degrees Recognized and Valid?",
			text: "The validity of an online degree is the same as a regular on-campus degree. Online degrees from accredited and recognized universities are considered valid and are widely accepted by employers and educational institutions. It's crucial to ensure that the online program is accredited by relevant educational authorities or accreditation bodies.",
		},
		{
			title: "Can I do an Online Degree? What are the Eligibility Criteria for Enrolling in an Online Degree?",
			text: "To enroll in an online degree program, eligibility criteria depend on the level of study. For bachelor's degrees, you generally need to have completed 12th grade (higher secondary education) or ITI from a recognized board. For postgraduate degrees, a bachelor's degree or a 3-year diploma is required. Additional eligibility requirements may apply at the college or university level, so it's important to check with the specific institution for exact details.",
		},
		{
			title: "What Career Opportunities Are Available After Completing an Online Degree?",
			text: "Completing an online degree opens up the same career opportunities as earning a traditional degree. Many industries require a bachelor's or master's degree as a fundamental qualification for various roles. With your online degree, you can:<br />Meet Job Requirements: Qualify for positions that list a degree as a mandatory requirement in fields like business, technology, healthcare, education, and more. <br />Advance Your Career: Become eligible for promotions and higher-level positions within your current organization or industry. <br/>Pursue Professional Certifications: Satisfy educational prerequisites for certifications or licenses needed in professions such as teaching, engineering, or accounting. <br/>Access New Industries: Transition into new fields where a degree is essential for entry, expanding your career options. <br/>An online degree equips you with the necessary credentials to meet industry standards, making you a competitive candidate for a wide range of job opportunities.",
		},
		{
			title: "How Do Online Classes and Examinations Work?",
			text: "Online classes are conducted through virtual learning environments where students can access lectures, study materials, and interact with instructors and peers. Assessments may include online quizzes, assignments, and proctored exams, which can be conducted online or at designated centers.",
		},
	],
};

export const STATIC_REELS_DATA = {
	reels: [
		{
			thumbnail_url:
				"https://cdn.apna.co/apna-learn/Reels/Thumbnail_Harshitha%27s%20journey%20with%20JAIN%20Online%20has%20been%20an%20enriching%20one!%20Hear%20her%20talk%20about%20how%20pursuing%20an%20online%20degree%20opened%20up%20a%20world%20of%20opportunities%20for%20her!%20%20%20%23JAINOnline%20%23ItAllAddsUp%20%23OnlineDe.jpg",
			video_url:
				"https://cdn.apna.co/apna-learn/Reels/Harshitha%27s%20journey%20with%20JAIN%20Online%20has%20been%20an%20enriching%20one!%20Hear%20her%20talk%20about%20how%20pursuing%20an%20online%20degree%20opened%20up%20a%20world%20of%20opportunities%20for%20her!%20%20%20%23JAINOnline%20%23ItAllAddsUp%20%23OnlineDegree%20%23OnlineCourses%20%23Testimonial.mp4",
		},
		{
			thumbnail_url:
				"https://cdn.apna.co/apna-learn/Reels/Thumbail_%23CareerLevelUpStories%2CAdarsh%2C%20our%20proud-placed%20online%20learner%20from%20Manipal%20Academy%20of%20Higher%20Education%2C%20shares%20his%20journey%20of%20transitioning%20from%20a%20developer%20role%20to%20securing%20a%20Data%20Engineer%20position%20at%20EY%20with%20the%20support%20of%20our%20placement.jpg",
			video_url:
				"https://cdn.apna.co/apna-learn/Reels/%23CareerLevelUpStories%2CAdarsh%2C%20our%20proud-placed%20online%20learner%20from%20Manipal%20Academy%20of%20Higher%20Education%2C%20shares%20his%20journey%20of%20transitioning%20from%20a%20developer%20role%20to%20securing%20a%20Data%20Engineer%20position%20at%20EY%20with%20the%20support%20of%20our%20placement%20t.mp4",
		},
		{
			thumbnail_url:
				"https://cdn.apna.co/apna-learn/Reels/Thumbail_I%20believe%20that%20success%20comes%20to%20those%20who%20prepare%20for%20it%20%2C%20as%20I%20did.%20The%20stepping%20stone%20for%20success%20is%20education%20%26%20Amity%20Online%20brings%20online%20degree%20programmes%20to%20prepare%20you%20for%20success.That%20too%20with%20flexibility%20of%20learning%20anytime%2C%20anywhe.jpg",
			video_url:
				"https://cdn.apna.co/apna-learn/Reels/I%20believe%20that%20success%20comes%20to%20those%20who%20prepare%20for%20it%20%2C%20as%20I%20did.%20The%20stepping%20stone%20for%20success%20is%20education%20%26%20Amity%20Online%20brings%20online%20degree%20programmes%20to%20prepare%20you%20for%20success.That%20too%20with%20flexibility%20of%20learning%20anytime%2C%20anywhe.mp4",
		},
		{
			thumbnail_url:
				"https://cdn.apna.co/apna-learn/Reels/Thumbail_Meet%20Akshara%20Nambiar%2C%20who%20upskilled%20with%20our%20Online%20MBA%20program%20while%20working%20at%20Accenture%20India.%20Discover%20how%20our%20flexible%20program%20empowered%20her%20career%2C%20and%20how%20it%20can%20do%20the%20same%20for%20you.%20Thank%20you%2C%20Akshara%2C%20for%20inspiring%20professionals%20to.jpg",
			video_url:
				"https://cdn.apna.co/apna-learn/Reels/Meet%20Akshara%20Nambiar%2C%20who%20upskilled%20with%20our%20Online%20MBA%20program%20while%20working%20at%20Accenture%20India.%20Discover%20how%20our%20flexible%20program%20empowered%20her%20career%2C%20and%20how%20it%20can%20do%20the%20same%20for%20you.%20Thank%20you%2C%20Akshara%2C%20for%20inspiring%20professionals%20to.mp4",
		},
		{
			thumbnail_url:
				"https://cdn.apna.co/apna-learn/Reels/Thumbail_Motherhood%20didn%27t%20stop%20Asma%27s%20quest%20for%20career%20growth!%20The%20way%20she%20implemented%20her%20learnings%20into%20her%20work%20is%20commendable.%20%20We%27re%20ecstatic%20to%20be%20a%20crucial%20part%20of%20her%20academic%20and%20professional%20journey%20and%20elated%20to%20have%20successfully%20contrib.jpg",
			video_url:
				"https://cdn.apna.co/apna-learn/Reels/Motherhood%20didn%27t%20stop%20Asma%27s%20quest%20for%20career%20growth%21%20The%20way%20she%20implemented%20her%20learnings%20into%20her%20work%20is%20commendable.%20%20We%27re%20ecstatic%20to%20be%20a%20crucial%20part%20of%20her%20academic%20and%20professional%20journey%20and%20elated%20to%20have%20successfully%20contrib.mp4",
		},
	],

	points: [
		{
			text: "As per UGC, Online degrees are <strong>equal to on-campus degrees</strong></string>",
			sub_text: "",
			icon: CrownCheckIcon,
		},
		{
			text: "Earn up to <strong>3x more salary</strong>, unlock promotions faster",
			sub_text: "",
			icon: WalletV2Icon, // You can replace this with different icons
		},
		{
			text: "Flexible learning, <strong>without quitting your job</strong>",
			sub_text: "",
			icon: SuitcaseFilledIcon,
		},
		{
			text: "Get top-university degrees at <strong>70% lower cost</strong>",
			sub_text: "",
			icon: CertificateIcon,
		},
		{
			text: "Alumni status and <strong>placement assistance</strong>",
			sub_text: "",
			icon: GraduationHatIcon,
		},
	],
	header_text: "Why So Many Professionals Opt for Online Education",
};

export const TOP_RECRUITERS = [
	{
		id: 0,
		icon_url:
			"https://cdn.apna.co/apna-learn/Top%20Recruiters%20Logos/aditya%20birla%20capital.png",
		name: "Aditya Birla Capital",
		slug: "aditya-birla-capital",
	},
	{
		id: 1,
		icon_url: "https://cdn.apna.co/apna-learn/Top%20Recruiters%20Logos/amazon.png",
		name: "Amazon",
		slug: "amazon",
	},
	{
		id: 2,
		icon_url:
			"https://cdn.apna.co/apna-learn/Top%20Recruiters%20Logos/axis%20bank.png",
		name: "Axis Bank",
		slug: "axis-bank",
	},
	{
		id: 3,
		icon_url: "https://cdn.apna.co/apna-learn/Top%20Recruiters%20Logos/bigbasket.png",
		name: "BigBasket",
		slug: "bigbasket",
	},
	{
		id: 4,
		icon_url: "https://cdn.apna.co/apna-learn/Top%20Recruiters%20Logos/blinkit.png",
		name: "Blinkit",
		slug: "blinkit",
	},
	{
		id: 5,
		icon_url: "https://cdn.apna.co/apna-learn/Top%20Recruiters%20Logos/cred.png",
		name: "CRED",
		slug: "cred",
	},
	{
		id: 6,
		icon_url: "https://cdn.apna.co/apna-learn/Top%20Recruiters%20Logos/flipkart.png",
		name: "Flipkart",
		slug: "flipkart",
	},
	{
		id: 7,
		icon_url:
			"https://cdn.apna.co/apna-learn/Top%20Recruiters%20Logos/hdfc%20bank.png",
		name: "HDFC Bank",
		slug: "hdfc-bank",
	},
	{
		id: 8,
		icon_url: "https://cdn.apna.co/apna-learn/Top%20Recruiters%20Logos/jio.png",
		name: "Jio",
		slug: "jio",
	},
	{
		id: 9,
		icon_url: "https://cdn.apna.co/apna-learn/Top%20Recruiters%20Logos/ola.png",
		name: "Ola",
		slug: "ola",
	},
	{
		id: 10,
		icon_url: "https://cdn.apna.co/apna-learn/Top%20Recruiters%20Logos/paytm.png",
		name: "Paytm",
		slug: "paytm",
	},
	{
		id: 11,
		icon_url: "https://cdn.apna.co/apna-learn/Top%20Recruiters%20Logos/swiggy.png",
		name: "Swiggy",
		slug: "swiggy",
	},
	{
		id: 12,
		icon_url:
			"https://cdn.apna.co/apna-learn/Top%20Recruiters%20Logos/tech%20mahindra.png",
		name: "Tech Mahindra",
		slug: "tech-mahindra",
	},
	{
		id: 13,
		icon_url: "https://cdn.apna.co/apna-learn/Top%20Recruiters%20Logos/uber.png",
		name: "Uber",
		slug: "uber",
	},
	{
		id: 14,
		icon_url:
			"https://cdn.apna.co/apna-learn/Top%20Recruiters%20Logos/urban%20company.png",
		name: "Urban Company",
		slug: "urban-company",
	},
	{
		id: 15,
		icon_url: "https://cdn.apna.co/apna-learn/Top%20Recruiters%20Logos/zomato-.png",
		name: "Zomato",
		slug: "zomato",
	},
];

export const REASONS_TO_CHOOSE_ONLINE_DEGREE = [
	"Switch to a new job",
	"Get a promotion in current job",
	"Increase your salary",
	"Get a degree from top institution",
	"Others",
];

export const LEARN_USER_DATA = "Learn User Data";

export const DUMMY_VALUE_FORM = "isFormOpen";

export const DUMMY_VALUE_OTP_SECTION = "isOtpSection";

export const DUMMY_VALUE_THANKYOU_SECTION = "isThankYouSection";

export const DUMMY_VALUE_STEP = "step";
export const FORM_VERSIONS = {
	VERSION_ZERO: "v0",
	VERSION_ONE: "v1",
	VERSION_TWO: "v2",
};
